<template>
  <div class="promotions-page content-container">
    <!--    <a-row v-if="isOwner || isDealer">-->
    <!--      <a-col :span="12">-->
    <!--        <h3>Настроить действующие акции:</h3>-->
    <!--      </a-col>-->
    <!--      <a-col :span="12">-->
    <!--        <router-link :to="{ name: 'CreatePromotion' }">-->
    <!--          Создать акцию-->
    <!--        </router-link>-->
    <!--      </a-col>-->
    <!--    </a-row>-->

    <PromotionsList />
  </div>
</template>

<script>
import PromotionsList from "@/components/promotions-list/PromotionsList"
import { mapGetters } from "vuex"

export default {
  name: "Promotions",
  computed: mapGetters({
    promotions: "promotions/promotions",
    loading: "promotions/loading",
    statuses: "promotions/statuses",
    isBuyer: "auth/isBuyer",
    isOwner: "auth/isOwner",
    isAdmin: "auth/isAdmin",
    isDealer: "auth/isDealer",
  }),
  components: {
    PromotionsList,
  },
}
</script>

<style lang="scss" scoped></style>
