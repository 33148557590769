<template>
  <div class="promotions">
    <div v-if="isDealer" class="d-flex-sb">
      <span>Настроить действующие папки</span>
      <div>
        <a-button @click="openModal(true)">Создать папку</a-button>
        <a-button @click="openModal(false)" class="ml-10">
          Редактировать
        </a-button>
      </div>
    </div>
    <a-tabs type="card" @change="handleReset">
      <a-tab-pane v-for="item in items" :key="item.id" :tab="item.name">
        {{ item.descr }}
      </a-tab-pane>
    </a-tabs>

    <a-modal
      :visible="visible"
      title=""
      @ok="handleOk"
      centered
      :maskClosable="false"
      :footer="null"
      @cancel="hideModal"
    >
      <template>
        <h3 v-if="visibleCreatePer">Создать папку</h3>
        <h3 v-if="!visibleCreatePer">Редактировать папку</h3>
        <a-form-item
          v-if="!visibleCreatePer"
          label="Документы бренда"
          class="filterFormItems"
        >
          <a-select placeholder="Наименование" class="selects">
            <template v-for="status in items">
              <a-select-option
                @click="takeBrand(status)"
                :key="status.id"
                :value="status.name"
              >
                {{ status.name }}
              </a-select-option>
            </template>
          </a-select>
        </a-form-item>
        <a-row>
          <a-col>
            <a-form-item label="Введите наименование">
              <a-input v-model="nameFolderNew" placeholder="Наименование" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item v-if="1" label="Загрузить документы">
          <a-upload name="file">
            <a-button class="file-upload">
              <a-icon type="upload" />
              Файл
            </a-button>
          </a-upload>
        </a-form-item>
        <div v-if="1" class="mt-20 flex-right">
          <a-button v-if="visibleCreatePer" @click="createFolder()">
            Создать
          </a-button>
          <a-button
            class="ml-10"
            v-if="!visibleCreatePer"
            @click="saveFolder()"
          >
            Сохранить
          </a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import moment from "moment"
export default {
  name: "PromotionsList",
  filters: {
    date(value) {
      return moment(value).format("DD.MM.YYYY")
    },
  },
  data() {
    return {
      pagination: {},
      items: [
        { id: 0, name: "Бренд 1", descr: "Сертификат1.PDF" },
        { id: 1, name: "Бренд 2", descr: "Сертификат2.PDF" },
      ],
      visible: false,
      nameFolderNew: "",
      visibleCreatePer: null,
      itemSel: null,
    }
  },
  mounted() {
    this.fetchPromotions()
  },
  computed: mapGetters({
    promotions: "promotions/promotions",
    loading: "promotions/loading",
    statuses: "promotions/statuses",
    isBuyer: "auth/isBuyer",
    isDealer: "auth/isDealer",
    isOwner: "auth/isOwner",
    isAdmin: "auth/isAdmin",
  }),
  methods: {
    ...mapActions({
      fetchPromotions: "promotions/fetchPromotions",
      toggleActive: "promotions/toggleActive",
    }),
    handleOk() {
      console.log("handleOk")
    },
    openModal(create) {
      this.visibleCreatePer = create
      this.visible = true
    },
    hideModal() {
      this.visible = false
      this.item = null
    },
    showRefundDetails(item) {
      this.item = item
      this.createdCard = this.item
      this.visible = true
    },
    createFolder() {
      const sample = { id: this.items.length, name: this.nameFolderNew }
      this.items.push(sample)
      this.visible = false
    },
    takeBrand(itemSel) {
      this.itemSel = itemSel
    },
    saveFolder() {
      const id = this.itemSel.id
      this.items = this.items.map(i => {
        if (i.id === id) console.log(i)
      })
    },
  },
}
</script>

<style lang="scss">
// TODO: change!

.selects {
  min-width: 200px;
}

.ml-10 {
  margin-left: 10px;
}
.d-flex-sb {
  display: flex;
  justify-content: space-between;
}
.ant-list-vertical .ant-list-item-action {
  margin-top: 0 !important;
}
</style>

<style lang="scss" scoped>
.promotions {
  .extra {
    min-width: 40vw;
  }

  .img {
    max-width: 30vw;
  }
}
</style>
