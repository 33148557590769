import { render, staticRenderFns } from "./Promotions.vue?vue&type=template&id=5feb0e3f&scoped=true&"
import script from "./Promotions.vue?vue&type=script&lang=js&"
export * from "./Promotions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5feb0e3f",
  null
  
)

export default component.exports